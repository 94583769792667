import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Spring } from 'react-spring/renderprops';
import { Emotion as EmotionType } from '../../types/Emotion';
import { GameId } from '../../types/Game';
import { Locale } from '../../types/Locale';
import Player from '../../types/Player';
import Emotion from '../Emotion';
import Header from '../Home/Header';
import PlayerInfo from '../Play/PlayerInfo';
import SVGIcon from '../SVGIcon';

interface GameNotFoundProps {
  changeLocale: (locale: Locale) => Promise<void>;
  changePlayerName: (name: string) => Promise<void>;
  gameId: GameId;
  player: Player;
}

const GameNotFound = ({
  changeLocale,
  gameId,
  player,
  changePlayerName,
}: GameNotFoundProps): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const redirectHome = () => {
    // Change page
    history.push('/');
  };

  return (
    <div className="App">
      <Header />
      <PlayerInfo
        changeLocale={changeLocale}
        playerName={player.name}
        changeName={changePlayerName}
      />
      <Spring
        from={{ opacity: 0, transform: 'translate(-3rem,0)' }}
        to={{ opacity: 1, transform: 'translate(0,0)' }}
        delay={200}
      >
        {(props) => (
          <div style={props} className="boardError">
            <div className="btnWrapper">
              <SVGIcon name="medical" />
              <button onClick={redirectHome}>
                {t('page_not_found.action_button')}
              </button>
            </div>
            <div className="errorWrap">
              <p>
                <Trans
                  i18nKey="page_not_found.funny_message"
                  components={{
                    b: <b />,
                    scary: <Emotion emotion={EmotionType.SCARY} />,
                  }}
                />
              </p>
              <p>{t('page_not_found.invalid_game', { gameId })}</p>
            </div>
          </div>
        )}
      </Spring>
      <Spring
        from={{ opacity: 0, transform: 'translate(3rem,0)' }}
        to={{ opacity: 1, transform: 'translate(0,0)' }}
        delay={500}
      >
        {(props) => <ul style={props} className="handPlay"></ul>}
      </Spring>
    </div>
  );
};

export default GameNotFound;
