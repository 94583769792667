import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GameType } from '../../types/GameType';
import { Locale } from '../../types/Locale';

interface GameOptionsProps {
  createGame: (locale: Locale, gameType: GameType) => Promise<void>;
}

const GameOptions = ({ createGame }: GameOptionsProps): JSX.Element | null => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState<Locale>(i18n.language as Locale);
  const [gameType, setGameType] = useState<GameType>(GameType.SOFT);

  return (
    <div className="btnWrapper">
      <div className="gameOptions">
        <p>{t('game.options.locale.description')}</p>
        <select
          value={locale}
          onChange={(event) => setLocale(event.target.value as Locale)}
        >
          {Object.values(Locale).map((locale) => (
            <option key={locale} value={locale}>
              {t(`game.options.locale.choice.${locale}`)}
            </option>
          ))}
        </select>
        <p>{t('game.options.game_type.description')}</p>
        <select
          value={gameType}
          onChange={(event) => setGameType(event.target.value as GameType)}
        >
          {Object.values(GameType).map((gameType) => (
            <option key={gameType} value={gameType}>
              {t(`game.options.game_type.choice.${gameType}`)}
            </option>
          ))}
        </select>
      </div>
      <button onClick={() => createGame(locale, gameType)}>
        {t('game.navigation.confirm_new_game')}
      </button>
    </div>
  );
};

export default GameOptions;
