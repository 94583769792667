import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface LoadingGameProps {
  loadGame: () => Promise<void>;
}

const LoadingGame = ({ loadGame }: LoadingGameProps): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    loadGame();
  }, [loadGame]);

  return <div>{t('game.loading')}</div>;
};

export default LoadingGame;
