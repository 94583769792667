import assert from 'assert';
import classNames from 'classnames';
import QRCode from 'qrcode.react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ManualShare from './ManualShare';
import ShareSection from './ShareSection';

interface ActionSectionProps {
  isOwner: boolean;
  hasJoined: boolean;
  joinGame: () => Promise<void>;
  startGame: () => Promise<void>;
}

const ActionSection = ({
  isOwner,
  hasJoined,
  joinGame,
  startGame,
}: ActionSectionProps): JSX.Element => {
  const [isCopied, setCopied] = useState<boolean>(false);
  const [isShared, setShared] = useState<boolean>(false);

  const { t } = useTranslation();

  const url = window.location.href;
  const canShare = undefined !== navigator.share;
  const canCopy = undefined !== navigator.clipboard;
  const hasActionOnWrapper = isOwner || (!hasJoined && (canShare || canCopy));

  const nativeShare = async () => {
    assert(navigator.share, t('share.native.unavailable'));
    await navigator.share({
      title: t('share.native.title'),
      text: t('share.native.text'),
      url: url,
    });
    setShared(true);

    setTimeout(() => {
      setShared(false);
    }, 2000);
  };

  const nativeCopy = async () => {
    await navigator.clipboard.writeText(url);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleShare = async () => {
    if (canShare) {
      nativeShare();
    } else if (canCopy) {
      nativeCopy();
    }
  };

  if (isOwner || !hasJoined) {
    return (
      <div className="btnWrapper">
        <div
          className={classNames('actionSectionWrapper', {
            hasAction: hasActionOnWrapper,
          })}
          onClick={handleShare}
        >
          <QRCode value={url} renderAs="svg" className="qrCode" />
          <ShareSection shareUrl={url} canCopy={canCopy} canShare={canShare} />
        </div>
        <button onClick={isOwner ? startGame : joinGame}>
          {isShared
            ? t('share.confirmation.shared')
            : isCopied
            ? t('share.confirmation.copied')
            : isOwner
            ? t('game.navigation.start')
            : t('game.navigation.join')}
        </button>
      </div>
    );
  }

  return (
    <div className="btnWrapper">
      <div className="actionSectionWrapper">
        <QRCode value={url} renderAs="svg" className="qrCode" />
        {!(canShare || canCopy) && <ManualShare shareUrl={url} />}
      </div>
      <button onClick={handleShare}>
        {isShared
          ? t('share.confirmation.shared')
          : isCopied
          ? t('share.confirmation.copied')
          : canShare
          ? t('share.action_button.share')
          : canCopy
          ? t('share.action_button.copy')
          : t('share.action_button.manual')}
      </button>
    </div>
  );
};

export default ActionSection;
