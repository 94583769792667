import React from 'react';
import { Trans } from 'react-i18next';

const Credits = (): JSX.Element => (
  <p className="credits">
    <Trans
      i18nKey="credits.developers"
      components={{
        link_solene: (
          // eslint-disable-next-line
          <a
            href="https://www.sodev.me/"
            target="_blank"
            rel="noopener noreferrer"
          />
        ),
        link_nicolas: (
          // eslint-disable-next-line
          <a
            href="https://www.linkedin.com/in/severinnicolas/"
            target="_blank"
            rel="noopener noreferrer"
          />
        ),
      }}
    ></Trans>
  </p>
);

export default Credits;
