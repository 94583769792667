import classNames from 'classnames';
import React from 'react';
import { animated, useTransition } from 'react-spring';
import Card from '../../types/Card';
import SVGIcon from '../SVGIcon';

interface HandProps {
  handCards: Array<Card>;
  hasPlayed: boolean;
  playCard: (card: Card) => Promise<void>;
}

const Hand = ({ handCards, hasPlayed, playCard }: HandProps): JSX.Element => {
  const transition = useTransition(handCards, (handCards) => handCards.id, {
    from: {
      opacity: 0,
      marginLeft: -50,
      marginRight: 50,
    },
    enter: [
      {
        opacity: 1,
      },
      {
        marginLeft: 0,
        marginRight: 0,
      },
    ],
    leave: [
      {
        marginLeft: -50,
        marginRight: 50,
        opacity: 0,
      },
    ],
    config: {
      duration: 400,
    },
    trail: 50,
  });

  return (
    <ul className={classNames('handPlay', { hasPlayed })}>
      {transition.map(
        ({ item: card, key, props }) =>
          -1 !== handCards.indexOf(card) && (
            <animated.li
              key={key}
              style={props}
              className="handCard"
              onClick={() => playCard(card)}
            >
              <span>{card.text}</span>
              <div className="virusMini">
                <SVGIcon name="virus" />
              </div>
              <div className="arrow">
                <SVGIcon name="arrow" />
              </div>
            </animated.li>
          ),
      )}
    </ul>
  );
};

export default Hand;
