import React from 'react';
import { useTranslation } from 'react-i18next';
import { animated, useTransition } from 'react-spring';
import SVGIcon from '../SVGIcon';

interface RoundCardProps {
  canChangeSetCards: boolean;
  hasNextRound: boolean;
  isGameFinished: boolean;
  isShowingResults: boolean;
  newGame: () => Promise<void>;
  nextRound: () => void;
  roundCard: string;
  toggleResults: () => void;
  changeSetCards: () => Promise<void>;
}

const RoundCard = ({
  hasNextRound,
  isGameFinished,
  isShowingResults,
  newGame,
  nextRound,
  roundCard,
  toggleResults,
  changeSetCards,
  canChangeSetCards,
}: RoundCardProps): JSX.Element => {
  const { t } = useTranslation();

  const transition = useTransition(roundCard, null, {
    from: { opacity: 0, marginLeft: -50, display: 'none' },
    enter: { opacity: 1, marginLeft: 0, display: 'flex' },
    leave: { opacity: 0, marginLeft: -50, display: 'none' },
  });

  let card;
  if (isShowingResults) {
    card = <h2>{t('game.navigation.results')}</h2>;
  } else {
    card = transition.map(({ item, key, props }) => (
      <animated.div key={key} style={props} className="wrapRoundCard">
        <h2>{item}</h2>
      </animated.div>
    ));
  }

  return (
    <div className="roundCard">
      {card}
      <div className="navigation">
        <div className="nav1">
          {hasNextRound && !isShowingResults && !isGameFinished ? (
            <button
              onClick={nextRound}
              className="roundBtn"
              aria-label={t('game.navigation.next_round')}
            >
              <SVGIcon name="rightArrow" />
            </button>
          ) : null}
        </div>
        <div className="nav2">
          {isGameFinished ? (
            <button onClick={toggleResults}>
              {isShowingResults
                ? t('game.navigation.review_rounds')
                : t('game.navigation.results')}
            </button>
          ) : null}
          {isGameFinished ? (
            <button onClick={newGame}>{t('game.navigation.new_game')}</button>
          ) : null}
        </div>
        <div className="nav3">
          {canChangeSetCards && (
            <button
              onClick={changeSetCards}
              className="roundBtn"
              aria-label={t('game.change_cards')}
            >
              <SVGIcon name="refresh" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoundCard;
