import React, { Reducer, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory } from 'react-router-dom';
import {
  acceptAllCookies,
  rejectAllCookies,
} from '../actions/cookieBannerActions';
import {
  changeSetCards as changeSetCardsAction,
  createGame as createGameAction,
  createNextGame as createNextGameAction,
  joinGame as joinGameAction,
  loadGame as loadGameAction,
  playCard as playCardAction,
  refreshGame as refreshGameAction,
  startGame as startGameAction,
  voteForCard as voteForCardAction,
} from '../actions/gameActions';
import {
  changeName as changePlayerNameAction,
  login as loginAction,
} from '../actions/playerActions';
import { withErrorHandler, withSwallowError } from '../helpers/errorUtils';
import {
  initializeGoogleAnalytics,
  setGoogleAnalyticsHistoryListener,
  withChangeLocaleEvent,
  withChangePlayerNameEvent,
  withChangeSetCardsEvent,
  withCreateGameEvent,
  withCreateNextGameEvent,
  withJoinGameEvent,
  withLoadGameEvent,
  withLoginEvent,
  withPlayCardEvent,
  withRefreshGameEvent,
  withStartGameEvent,
  withVoteForCardEvent,
} from '../helpers/googleAnalyticsUtils';
import gameReducer, {
  initialState,
  lazyStateInitializer,
} from '../reducers/reducer';
import '../styles/App.scss';
import { GameAction } from '../types/Action';
import { Locale } from '../types/Locale';
import { GameState } from '../types/State';
import PageWithGameId from './BeforePlay/PageWithGameId';
import CookieBanner from './CookieBanner';
import ErrorBanner from './ErrorBanner';
import Home from './Home/Home';
import PageNotFound from './PageNotFound';

const App = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  const [
    {
      error,
      game,
      isGameNotFound,
      // TODO: isGameLoading,
      hasAnsweredCookieBanner,
      player,
      accessToken,
    },
    dispatch,
  ] = useReducer<Reducer<GameState, GameAction>, GameState>(
    gameReducer,
    initialState,
    lazyStateInitializer,
  );

  // Initialize Analytics when the banner has been answered.
  useEffect(() => {
    initializeGoogleAnalytics();
  }, [hasAnsweredCookieBanner]);

  const history = useHistory();

  setGoogleAnalyticsHistoryListener(history);

  const withErrorBanner = withErrorHandler(dispatch, t);

  const login = withLoginEvent(withErrorBanner(loginAction(dispatch)));

  const changeLocale = withChangeLocaleEvent(
    async (locale: Locale): Promise<void> => {
      if ('true' === sessionStorage.getItem('accepted-cookies')) {
        sessionStorage.setItem('locale', locale);
      }

      i18n.changeLanguage(locale);
    },
  );
  const changePlayerName = withSwallowError(
    withChangePlayerNameEvent(changePlayerNameAction(dispatch)),
  );
  const createGame = withSwallowError(
    withCreateGameEvent(
      withErrorBanner(createGameAction(dispatch, login, history, player)),
    ),
  );
  const createNextGame = withSwallowError(
    withCreateNextGameEvent(
      withErrorBanner(createNextGameAction(dispatch, history)),
    ),
  );
  const loadGame = withSwallowError(
    withLoadGameEvent(withErrorBanner(loadGameAction(dispatch, login, player))),
  );
  const refreshGame = withSwallowError(
    withRefreshGameEvent(withErrorBanner(refreshGameAction(dispatch))),
  );
  const startGame = withSwallowError(
    withStartGameEvent(withErrorBanner(startGameAction(dispatch))),
  );
  const joinGame = withSwallowError(
    withJoinGameEvent(withErrorBanner(joinGameAction(dispatch))),
  );
  const playCard = withSwallowError(
    withPlayCardEvent(withErrorBanner(playCardAction(dispatch))),
  );
  const voteForCard = withSwallowError(
    withVoteForCardEvent(withErrorBanner(voteForCardAction(dispatch))),
  );
  const changeSetCards = withSwallowError(
    withChangeSetCardsEvent(withErrorBanner(changeSetCardsAction(dispatch))),
  );

  const acceptCookies = acceptAllCookies(
    dispatch,
    i18n.language,
    accessToken || null,
  );
  const rejectCookies = rejectAllCookies(dispatch);

  return (
    <Switch>
      <Route path="/" exact>
        <Home createGame={createGame} changeLocale={changeLocale} />
        <CookieBanner
          accept={acceptCookies}
          reject={rejectCookies}
          hasAnswered={hasAnsweredCookieBanner}
        />
        <ErrorBanner message={error} />
      </Route>
      <Route path="/game/:id" exact>
        <PageWithGameId
          changeLocale={changeLocale}
          changePlayerName={changePlayerName}
          game={game}
          newGame={createNextGame}
          joinGame={joinGame}
          player={player}
          playCard={playCard}
          startGame={startGame}
          loadGame={loadGame}
          refreshGame={refreshGame}
          voteForCard={voteForCard}
          isGameNotFound={isGameNotFound}
          changeSetCards={changeSetCards}
        />
        <CookieBanner
          accept={acceptCookies}
          reject={rejectCookies}
          hasAnswered={hasAnsweredCookieBanner}
        />
        <ErrorBanner message={error} />
      </Route>
      <Route path="*">
        <PageNotFound changeLocale={changeLocale} />
      </Route>
    </Switch>
  );
};

export default App;
