import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Spring } from 'react-spring/renderprops';
import Credits from '../Credits';
import Emotion, { emotions, getDescription } from '../Emotion';
import SVGIcon from '../SVGIcon';
import ActionSection from './ActionSection/ActionSection';

interface AnimationProps {
  animationStyle: {
    opacity: number;
    transform: string;
  };
}

interface BoardWaitProps {
  startGame: () => Promise<void>;
  hasJoined: boolean;
  isOwner: boolean;
  joinGame: () => Promise<void>;
}

const BoardWait = ({
  startGame,
  hasJoined,
  isOwner,
  joinGame,
  animationStyle,
}: BoardWaitProps & AnimationProps): JSX.Element => {
  const { t } = useTranslation();

  const NextButton = () => (
    <button className="roundBtn" aria-label="Next round">
      <SVGIcon name="rightArrow" />
    </button>
  );

  const ChangeCards = () => (
    <button className="roundBtn" aria-label="change cards">
      <SVGIcon name="refresh" />
    </button>
  );

  return (
    <div style={animationStyle} className="boardWait">
      <div className="rules">
        <p>{t('rules.options')}</p>
        <p>{t('rules.rounds')}</p>
        <ul>
          {emotions.map((emotion) => (
            <li key={emotion}>
              <Emotion emotion={emotion} /> {getDescription(emotion, t)}
            </li>
          ))}
        </ul>
        <p>
          <Trans
            i18nKey="rules.vote"
            components={{
              b: <b />,
            }}
          />
        </p>
        <p>
          <Trans
            i18nKey="rules.next_round"
            components={{
              b: <b />,
              next: <NextButton />,
            }}
          />
        </p>
        <p>
          <Trans
            i18nKey="rules.change_cards"
            components={{
              b: <b />,
              change: <ChangeCards />,
            }}
          />
        </p>
      </div>
      <ActionSection
        hasJoined={hasJoined}
        isOwner={isOwner}
        startGame={startGame}
        joinGame={joinGame}
      />
      <div className="banner">
        {isOwner ? (
          <p>{t('share.instruction.owner')}</p>
        ) : (
          <p>{t('share.instruction.not_owner')}</p>
        )}
        <Credits />
      </div>
    </div>
  );
};

const AnimatedBoardWait = (props: BoardWaitProps): JSX.Element => (
  <Spring
    from={{ opacity: 0, transform: 'translate(-3rem,0)' }}
    to={{ opacity: 1, transform: 'translate(0,0)' }}
    delay={200}
  >
    {(springProps) => <BoardWait {...props} animationStyle={springProps} />}
  </Spring>
);

export default AnimatedBoardWait;
