import React from 'react';
import { useTranslation } from 'react-i18next';
import SVGIcon from '../SVGIcon';

interface NavBlockProps {
  hasNextRound: boolean;
  hasPreviousRound: boolean;
  isShowingResults: boolean;
  nextRound: () => void;
  previousRound: () => void;
}

const NavBlock = ({
  hasNextRound,
  hasPreviousRound,
  isShowingResults,
  nextRound,
  previousRound,
}: NavBlockProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="navBtn">
      <button
        onClick={previousRound}
        className="roundBtn"
        aria-label={t('game.navigation.previous_round')}
        disabled={!hasPreviousRound || isShowingResults}
      >
        <SVGIcon name="leftArrow" />
      </button>
      <button
        onClick={nextRound}
        className="roundBtn"
        aria-label={t('game.navigation.next_round')}
        disabled={!hasNextRound || isShowingResults}
      >
        <SVGIcon name="rightArrow" />
      </button>
    </div>
  );
};

export default NavBlock;
