import { History } from 'history';
import {
  changeSetCards as changeSetCardsApi,
  createGame as createGameApi,
  createNextGame as createNextGameApi,
  joinGame as joinGameApi,
  loadGame as loadGameApi,
  playCard as playCardApi,
  startGame as startGameApi,
  voteForCard as voteForCardApi,
} from '../api/backEnd';
import { mapApiResponseToGame } from '../helpers/apiUtils';
import { GameActionDispatch, GameActionType } from '../types/Action';
import Card, { CardId } from '../types/Card';
import { Emotion } from '../types/Emotion';
import Game, { GameId } from '../types/Game';
import { GameType } from '../types/GameType';
import { Locale } from '../types/Locale';
import Player, { PlayerId } from '../types/Player';
import { RoundId } from '../types/Round';

const gameLoaded = (dispatch: GameActionDispatch, game: Game): void =>
  dispatch({
    type: GameActionType.GAME_LOADED,
    payload: { game },
  });

// TODO:
// const gameLoading = (dispatch: GameActionDispatch): void =>
//   dispatch({
//     type: GameActionType.GAME_LOADING,
//     payload: undefined,
//   });

const gameNotFound = (dispatch: GameActionDispatch): void =>
  dispatch({
    type: GameActionType.GAME_NOT_FOUND,
    payload: undefined,
  });

export const createGame = (
  dispatch: GameActionDispatch,
  login: () => Promise<void>,
  history: History,
  player?: Player,
) => async (locale: Locale, gameType: GameType): Promise<void> => {
  // TODO: gameLoading(dispatch);

  // Make sure the player is logged in first.
  if (undefined === player) {
    await login();
  }

  const response = await createGameApi(locale, gameType);
  const game = mapApiResponseToGame(response);

  gameLoaded(dispatch, game);

  history.push(`/game/${game.id}`);
};

export const createNextGame = (
  dispatch: GameActionDispatch,
  history: History,
) => async (gameId: GameId): Promise<void> => {
  const response = await createNextGameApi(gameId);
  const game = mapApiResponseToGame(response);

  gameLoaded(dispatch, game);

  history.push(`/game/${game.id}`);
};

export const joinGame = (dispatch: GameActionDispatch) => async (
  gameId: GameId,
): Promise<void> => {
  // TODO: gameLoading(dispatch);

  const response = await joinGameApi(gameId);
  const game = mapApiResponseToGame(response);

  gameLoaded(dispatch, game);
};

export const loadGame = (
  dispatch: GameActionDispatch,
  login: () => Promise<void>,
  player?: Player,
) => async (gameId: GameId): Promise<void> => {
  try {
    // TODO: gameLoading(dispatch);

    // Make sure the player is logged in first.
    if (undefined === player) {
      await login();
    }

    const response = await loadGameApi(gameId);
    const game = mapApiResponseToGame(response);

    gameLoaded(dispatch, game);
  } catch (error) {
    gameNotFound(dispatch);
  }
};

export const playCard = (dispatch: GameActionDispatch) => async (
  gameId: GameId,
  roundId: RoundId,
  card: Card,
): Promise<void> => {
  const response = await playCardApi(gameId, roundId, card);
  const game = mapApiResponseToGame(response);

  gameLoaded(dispatch, game);
};

export const refreshGame = (dispatch: GameActionDispatch) => async (
  gameId: GameId,
): Promise<void> => {
  const response = await loadGameApi(gameId);
  const game = mapApiResponseToGame(response);

  gameLoaded(dispatch, game);
};

export const startGame = (dispatch: GameActionDispatch) => async (
  gameId: GameId,
): Promise<void> => {
  const response = await startGameApi(gameId);
  const game = mapApiResponseToGame(response);

  gameLoaded(dispatch, game);
};

export const voteForCard = (dispatch: GameActionDispatch) => async (
  gameId: GameId,
  roundId: RoundId,
  cardId: CardId,
  emotion: Emotion,
): Promise<void> => {
  const response = await voteForCardApi(gameId, roundId, cardId, emotion);
  const game = mapApiResponseToGame(response);

  gameLoaded(dispatch, game);
};

export const changeSetCards = (dispatch: GameActionDispatch) => async (
  gameId: GameId,
  playerId: PlayerId,
): Promise<void> => {
  const response = await changeSetCardsApi(gameId, playerId);
  const game = mapApiResponseToGame(response);

  gameLoaded(dispatch, game);
};
