import { AxiosError } from 'axios';
import { TFunction } from 'i18next';
import { selfDismissingErrorOccurred } from '../actions/errorActions';
import { GameActionDispatch } from '../types/Action';
import { ApiError } from '../types/Api';

// Wraps the function call with a try/catch and set the error
// handler message on error.
export const withErrorHandler = (
  dispatch: GameActionDispatch,
  t: TFunction,
) => <T extends any[], U>(
  callback: (...args: T) => Promise<U>,
): ((...args: T) => Promise<U>) => async (...args: T): Promise<U> => {
  try {
    return await callback(...args);
  } catch (error) {
    selfDismissingErrorOccurred(dispatch)(convertErrorToString(error, t));

    throw error;
  }
};

export const withSwallowError = <T extends any[]>(
  callback: (...args: T) => Promise<void>,
): ((...args: T) => Promise<void>) => async (...args: T): Promise<void> => {
  try {
    return await callback(...args);
  } catch (error) {
    console.debug('Swallowed error: ' + error);
  }
};

const convertErrorToString = (
  error: AxiosError<ApiError>,
  t: TFunction,
): string => {
  const message = error?.response?.data?.error || error.message || error;

  // Display the server generated message if any, or a generic one.
  return 'string' === typeof message ? message : t('error.unknown');
};
