import React from 'react';
import { GameType } from '../../types/GameType';
import { Locale } from '../../types/Locale';
import Board from './Board';
import Header from './Header';
import Info from './Info';
import Rules from './Rules';

interface HomeProps {
  changeLocale: (locale: Locale) => Promise<void>;
  createGame: (locale: Locale, gameType: GameType) => Promise<void>;
}

const Home = ({ changeLocale, createGame }: HomeProps): JSX.Element => (
  <div className="AppHome">
    <Header />
    <Info changeLocale={changeLocale} />
    <Board createGame={createGame} />
    <Rules />
  </div>
);

export default Home;
