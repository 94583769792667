import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Locale } from '../../types/Locale';
import UserOptions from '../Options/UserOptions';
import SVGIcon from '../SVGIcon';

interface PlayerInfo {
  changeLocale: (locale: Locale) => Promise<void>;
  playerName: string;
  changeName: (name: string) => Promise<void>;
}

const PlayerInfo = ({
  changeLocale,
  playerName,
  changeName,
}: PlayerInfo): JSX.Element => {
  const [newName, setNewName] = useState<string>(playerName);
  const [isEditing, setEditing] = useState<boolean>(false);
  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    nameInputRef.current?.focus();
  });

  const isEnterKey = (event: KeyboardEvent) => {
    return (
      ('key' in event && event.key === 'Enter') ||
      ('which' in event && event.which === 13) ||
      ('keyCode' in event && event.keyCode === 13)
    );
  };

  const updateName = () => {
    changeName(newName);
    setEditing(false);
  };

  return (
    <div className="info">
      <div className="player">
        <SVGIcon name="doctor" />
        {isEditing ? (
          <input
            ref={nameInputRef}
            className="changeName"
            onChange={(event) => setNewName(event.target.value)}
            onBlur={() => updateName()}
            onKeyPress={(event) => isEnterKey(event) && updateName()}
            value={newName}
          />
        ) : (
          <p className="name" onClick={() => setEditing(true)}>
            {playerName}
          </p>
        )}
      </div>
      <UserOptions changeLocale={changeLocale} />
    </div>
  );
};

export default PlayerInfo;
