import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spring } from 'react-spring/renderprops';
import { GameType } from '../../types/GameType';
import { Locale } from '../../types/Locale';
import Credits from '../Credits';
import Emotion, { emotions } from '../Emotion';
import GameOptionsPopup from './GameOptions';
import NewGame from './NewGame';

interface AnimationProps {
  animationStyle: {
    opacity: number;
    transform: string;
  };
}

interface BoardProps {
  createGame: (locale: Locale, gameType: GameType) => Promise<void>;
}

const Board = ({
  createGame,
  animationStyle,
}: BoardProps & AnimationProps): JSX.Element => {
  const { t } = useTranslation();
  const [isShowingOptions, setShowingOptions] = useState<boolean>(false);

  return (
    <div className="boardHome" style={animationStyle}>
      {isShowingOptions ? (
        <GameOptionsPopup createGame={createGame} />
      ) : (
        <NewGame showOptions={() => setShowingOptions(true)} />
      )}
      <div className="banner">
        <p>{t('punchline')}</p>
        <p className="emojis">
          {emotions.map((emotion) => (
            <Emotion emotion={emotion} key={emotion} />
          ))}
        </p>
        <Credits />
      </div>
    </div>
  );
};

const AnimatedBoard = (props: BoardProps): JSX.Element => (
  <Spring
    from={{ opacity: 0, transform: 'translate(-3rem,0)' }}
    to={{ opacity: 1, transform: 'translate(0,0)' }}
    delay={200}
  >
    {(springProps) => <Board {...props} animationStyle={springProps} />}
  </Spring>
);

export default AnimatedBoard;
