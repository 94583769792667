import React from 'react';
import { useTranslation } from 'react-i18next';

interface ManualShareProps {
  shareUrl: string;
}

const ManualShare = ({ shareUrl }: ManualShareProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <label className="manualShare">
      <input type="text" readOnly={true} value={shareUrl} />
      <div>{t('share.action_text.manual')}</div>
    </label>
  );
};

export default ManualShare;
