import { GetPlayerApiResponse, WithGameApiResponse } from '../types/Api';
import Card, { PlayedCard } from '../types/Card';
import Game from '../types/Game';
import Player, { PlayerWithCards } from '../types/Player';
import Round from '../types/Round';
import Vote from '../types/Vote';

export const mapApiResponseToGame = (response: WithGameApiResponse): Game => ({
  id: response.game._id,
  options: {
    gameType: response.game.gameType,
    locale: response.game.language,
  },
  owner: response.game.owner,
  players: response.game.players.map(
    (player): PlayerWithCards => ({
      id: player.userID,
      name: player.pseudo,
      changeCards: player.changeCards,
      playerCards: player.playerCards.map(
        (card): Card => ({
          id: card.id,
          text: card.text,
        }),
      ),
    }),
  ),
  rounds: response.game.rounds.map(
    (round): Round => ({
      id: round._id,
      playedCards: round.playedCards.map(
        (card): PlayedCard => ({
          id: card._id,
          handCard: [card.handCard].map(
            (card): Card => ({
              id: card._id,
              text: card.text,
            }),
          )[0],
          playerId: card.playerId,
          votes: card.votes.map(
            (vote): Vote => ({
              emotion: vote.emotion,
              playerId: vote.playerId,
            }),
          ),
        }),
      ),
      roundCard: round.roundCard,
      status: round.roundStatus,
    }),
  ),
  status: response.game.status,
  nextGameId: response.game.nextGameId,
});

export const mapApiResponseToPlayer = ({
  userId,
  pseudo,
}: GetPlayerApiResponse): Player => ({ id: userId, name: pseudo });
