import axios from 'axios';
import {
  CreateGameApiResponse,
  CreateNextGameApiResponse,
  GetGameApiResponse,
  GetPlayerApiResponse,
  JoinGameApiResponse,
  PlayCardApiResponse,
  StartGameApiResponse,
  UpdatePlayerApiResponse,
  VoteApiResponse,
} from '../types/Api';
import Card, { CardId } from '../types/Card';
import { Emotion } from '../types/Emotion';
import { GameId } from '../types/Game';
import { GameType } from '../types/GameType';
import { Locale } from '../types/Locale';
import { PlayerId } from '../types/Player';
import { RoundId } from '../types/Round';

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    common: {
      // Initializes auth token if player already logged in before,
      // from session storage.
      Authorization: `Bearer ${sessionStorage.getItem('access-token')}`,
    },
  },
});

export const getPlayer = async (): Promise<GetPlayerApiResponse> => {
  const response = (await api.get<GetPlayerApiResponse>(`/user`)).data;

  // Update default Authorization header.
  api.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;

  return response;
};

export const updatePlayer = async (
  name: string,
): Promise<UpdatePlayerApiResponse> => {
  return (
    await api.put<UpdatePlayerApiResponse>(`/user`, {
      pseudo: name,
    })
  ).data;
};

export const loadGame = async (gameId: GameId): Promise<GetGameApiResponse> => {
  return (await api.get<GetGameApiResponse>(`/game/${gameId}`)).data;
};

export const createGame = async (
  locale: Locale,
  gameType: GameType,
): Promise<CreateGameApiResponse> => {
  return (
    await api.post<CreateGameApiResponse>('/game', {
      language: locale,
      gameType,
    })
  ).data;
};

export const createNextGame = async (
  gameId: GameId,
): Promise<CreateNextGameApiResponse> => {
  return (await api.post<CreateNextGameApiResponse>(`/game/${gameId}/nextGame`))
    .data;
};

export const joinGame = async (
  gameId: GameId,
): Promise<JoinGameApiResponse> => {
  return (await api.put<JoinGameApiResponse>(`/game/${gameId}/players`)).data;
};

export const startGame = async (
  gameId: GameId,
): Promise<StartGameApiResponse> => {
  return (await api.put<StartGameApiResponse>(`/game/${gameId}`)).data;
};

export const playCard = async (
  gameId: GameId,
  roundId: RoundId,
  card: Card,
): Promise<PlayCardApiResponse> => {
  return (
    await api.post<PlayCardApiResponse>(`/game/${gameId}/round/${roundId}`, {
      card,
    })
  ).data;
};

export const voteForCard = async (
  gameId: GameId,
  roundId: RoundId,
  cardId: CardId,
  emotion: Emotion,
): Promise<VoteApiResponse> => {
  return (
    await api.put<VoteApiResponse>(
      `/game/${gameId}/round/${roundId}/playedCards/${cardId}`,
      {
        emotion,
      },
    )
  ).data;
};

export const changeSetCards = async (
  gameId: GameId,
  playerId: PlayerId,
): Promise<VoteApiResponse> => {
  return (
    await api.put<VoteApiResponse>(
      `/game/${gameId}/players/${playerId}/playerCards`,
    )
  ).data;
};
