import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Locale } from './types/Locale';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: '/translations/{{lng}}.json',
    },

    detection: {
      lookupSessionStorage: 'locale',
      order: ['sessionStorage', 'navigator'],
      caches: [], // Let the app manage its cache, as it depends if user accepted cookies.
    },

    fallbackLng: Locale.ENGLISH,

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: false,
    },

    whitelist: Object.values(Locale),
  });

export default i18n;
