import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Spring } from 'react-spring/renderprops';
import { Emotion as EmotionType } from '../types/Emotion';
import { Locale } from '../types/Locale';
import Emotion from './Emotion';
import Header from './Home/Header';
import Info from './Home/Info';
import SVGIcon from './SVGIcon';

interface PageNotFoundProps {
  changeLocale: (locale: Locale) => Promise<void>;
}

const PageNotFound = ({ changeLocale }: PageNotFoundProps): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const redirectHome = () => {
    // Change page
    history.push('/');
  };

  return (
    <div className="App">
      <Header />
      <Info changeLocale={changeLocale} />
      <Spring
        from={{ opacity: 0, transform: 'translate(-3rem,0)' }}
        to={{ opacity: 1, transform: 'translate(0,0)' }}
        delay={200}
      >
        {(props) => (
          <div className="boardError" style={props}>
            <div className="btnWrapper">
              <SVGIcon name="medical" />
              <button onClick={redirectHome}>
                {t('page_not_found.action_button')}
              </button>
            </div>
            <div className="errorWrap">
              <p>
                <Trans
                  i18nKey="page_not_found.funny_message"
                  components={{
                    b: <b />,
                    scary: <Emotion emotion={EmotionType.SCARY} />,
                  }}
                />
              </p>
              <p>
                {t('page_not_found.invalid_location', {
                  location: location.pathname,
                })}
              </p>
            </div>
          </div>
        )}
      </Spring>
      <Spring
        from={{ opacity: 0, transform: 'translate(3rem,0)' }}
        to={{ opacity: 1, transform: 'translate(0,0)' }}
        delay={500}
      >
        {(props) => <ul style={props} className="handPlay"></ul>}
      </Spring>
    </div>
  );
};

export default PageNotFound;
