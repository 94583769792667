import Card, { CardInfo } from '../types/Card';
import { Emotion } from '../types/Emotion';
import Game from '../types/Game';
import Player, { PlayerId } from '../types/Player';
import { EmotionStats, PlayerStats, RankedPlayer } from '../types/Results';
import { RoundId } from '../types/Round';
import { Status } from '../types/Status';
import Vote from '../types/Vote';

export const hasPlayerJoinedGame = (game: Game, player: Player): boolean =>
  game.players.filter((gamePlayer) => player.id === gamePlayer.id).length > 0;

export const isGameFinished = (game: Game): boolean =>
  Status.FINISHED === game.status;

export const isGameWaitingForPlayers = (game: Game): boolean =>
  Status.WAITING === game.status;

const hasPlayerVotedInRound = (
  game: Game,
  roundIndex: number,
  player: Player,
): boolean =>
  game.rounds[roundIndex].playedCards
    .flatMap((card) => card.votes)
    .filter((vote) => vote.playerId === player.id).length !== 0;

export const hasPlayerPlayedInRound = (
  game: Game,
  roundIndex: number,
  player: Player,
): boolean =>
  game.rounds[roundIndex].playedCards.filter(
    (card) => card.playerId === player.id,
  ).length !== 0;

export const getLastRoundIndex = (game: Game): number => game.rounds.length - 1;

export const isGameOwner = (game: Game, player: Player): boolean =>
  game.owner === player.id;

export const getRoundSentence = (game: Game, roundIndex: number): string =>
  game.rounds[roundIndex].roundCard.sentence;

export const getPlayedCards = (
  game: Game,
  roundIndex: number,
  user: Player,
): Array<CardInfo> => {
  type PlayersPseudo = Record<PlayerId, string>;

  const round = game.rounds[roundIndex];
  const canVote = round.playedCards.length === game.players.length;
  const hasVoted = hasPlayerVotedInRound(game, roundIndex, user);
  const canShowVotes = Status.FINISHED === round.status;
  const players = game.players.reduce<PlayersPseudo>(
    (playersPseudo, player) => ({
      ...playersPseudo,
      [player.id]: player.name,
    }),
    {},
  );

  return round.playedCards.map<CardInfo>((card) => ({
    id: card.id,
    playedCard: card.handCard.text,
    player: players[card.playerId],
    isMyCard: card.playerId === user.id,
    canVote,
    hasVoted,
    canShowVotes,
    myVote: card.votes
      .filter((vote) => vote.playerId === user.id)
      .map((vote) => vote.emotion)[0],
    votes: canShowVotes ? card.votes.map((vote) => vote.emotion) : [],
  }));
};

export const getHandCards = (game: Game, user: Player): Array<Card> => {
  const player = game.players.filter((player) => player.id === user.id)[0];
  return undefined !== player && 'playerCards' in player
    ? player.playerCards
    : [];
};

export const canChangeCards = (game: Game, user: Player): boolean => {
  const player = game.players.filter((player) => player.id === user.id)[0];

  if (undefined !== player && 'changeCards' in player) {
    const cardChanges = player.changeCards;
    const remainingCards = player.playerCards.length;

    return remainingCards > 0 && cardChanges < 1 && !isGameFinished(game);
  }

  return false;
};

export const getRoundId = (game: Game, roundIndex: number): RoundId =>
  game.rounds[roundIndex].id;

export const hasPlayedInRound = (
  game: Game,
  roundIndex: number,
  player: Player,
): boolean =>
  game.rounds[roundIndex].playedCards.filter(
    (card) => card.playerId === player.id,
  ).length > 0;

export const getResults = (game: Game, player: Player): Array<RankedPlayer> => {
  interface VoteWithCardPlayerId extends Vote {
    cardPlayerId: PlayerId;
  }
  type VotesGroupedByCardPlayerId = Record<
    PlayerId,
    Array<VoteWithCardPlayerId>
  >;
  type EmotionsCount = Record<Emotion, number>;

  // Group all votes from all rounds by card players.
  const groupedVotes = game.rounds
    // Get all cards.
    .flatMap((round) => round.playedCards)
    // Get all votes.
    .flatMap((card) =>
      card.votes.map<VoteWithCardPlayerId>((vote) => ({
        ...vote,
        cardPlayerId: card.playerId,
      })),
    )
    // Group votes by player.
    .reduce<VotesGroupedByCardPlayerId>(
      (results, vote) => ({
        ...results,
        [vote.cardPlayerId]: [...(results[vote.cardPlayerId] || []), vote],
      }),
      {},
    );

  // Add back players who didn't get any vote.
  const playerVotes: VotesGroupedByCardPlayerId = {
    ...game.players.reduce<VotesGroupedByCardPlayerId>(
      (previous, player) => ({
        ...previous,
        [player.id]: [],
      }),
      {},
    ),
    ...groupedVotes,
  };

  return (
    Object.entries(playerVotes)
      .map<PlayerStats>(([cardPlayerId, votes]) => ({
        playerId: cardPlayerId,
        // Get the player pseudo
        player: game.players.filter(
          (gamePlayer) => gamePlayer.id === cardPlayerId,
        )[0].name,
        // Group votes by emotion
        emotions: Object.entries(
          votes.reduce<EmotionsCount>(
            (emotions, vote) => ({
              ...emotions,
              [vote.emotion]: (emotions[vote.emotion] || 0) + 1,
            }),
            {} as EmotionsCount,
          ),
        ).map<EmotionStats>(([emotion, count]) => ({
          emotion: emotion as Emotion,
          count,
        })),
        isMyself: player.id === cardPlayerId,
        score: votes.length,
      }))
      // Sort players by score descending
      .sort((player1, player2) => player2.score - player1.score)
      // Assign a rank to each player based on their position in the array
      .reduce<Array<RankedPlayer>>((players, player) => {
        const lastPlayer = players[players.length - 1];

        return [
          ...players,
          {
            ...player,
            rank:
              0 === players.length
                ? 1
                : lastPlayer.rank + (lastPlayer.score === player.score ? 0 : 1),
          },
        ];
      }, [])
  );
};
