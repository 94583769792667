import Game from './Game';
import Player from './Player';

interface Action<T, U> {
  type: T;
  payload: U;
}

export enum GameActionType {
  COOKIES_ACCEPT_ALL,
  COOKIES_REJECT_ALL,
  ERROR_CLEARED,
  ERROR_OCCURRED,
  GAME_LOADED,
  GAME_LOADING,
  GAME_NOT_FOUND,
  PLAYER_CHANGED_NAME,
  PLAYER_LOGGED_IN,
}

interface ErrorPayload {
  message: string;
}

interface GamePayload {
  game: Game;
}

interface PlayerPayload {
  player: Player;
}

interface AccessTokenPayload {
  accessToken: string;
}

type AcceptedAllCookies = Action<GameActionType.COOKIES_ACCEPT_ALL, undefined>;
type RejectedAllCookies = Action<GameActionType.COOKIES_REJECT_ALL, undefined>;

type ErrorCleared = Action<GameActionType.ERROR_CLEARED, undefined>;
type ErrorOccurred = Action<GameActionType.ERROR_OCCURRED, ErrorPayload>;

type GameLoadedAction = Action<GameActionType.GAME_LOADED, GamePayload>;
type GameLoadingAction = Action<GameActionType.GAME_LOADING, undefined>;
type GameNotFoundAction = Action<GameActionType.GAME_NOT_FOUND, undefined>;

type ChangedNameAction = Action<
  GameActionType.PLAYER_CHANGED_NAME,
  PlayerPayload
>;
type LoggedInAction = Action<
  GameActionType.PLAYER_LOGGED_IN,
  PlayerPayload & AccessTokenPayload
>;

export type GameAction =
  | AcceptedAllCookies
  | RejectedAllCookies
  | ErrorCleared
  | ErrorOccurred
  | GameLoadedAction
  | GameLoadingAction
  | GameNotFoundAction
  | ChangedNameAction
  | LoggedInAction;

export type GameActionDispatch = (action: GameAction) => void;
