import { History } from 'history';
import ReactGA, { EventArgs } from 'react-ga';
import {
  EventCategory,
  GameEventAction,
  UserEventAction,
} from '../types/Analytics';

export const initializeGoogleAnalytics = (): void => {
  // Don't initialize analytics if the user rejected it.
  if ('true' !== sessionStorage.getItem('accepted-analytics')) {
    return;
  }

  // Check whether the tracking ID is properly injected during the build.
  const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  if ('' === googleAnalyticsId || undefined === googleAnalyticsId) {
    console.error('Missing Google Analytics tracking ID');

    return;
  }

  // Enable debug in the dev environment to display additional console logs.
  ReactGA.initialize(googleAnalyticsId, {
    debug: 'development' === process.env.NODE_ENV,
  });

  // Also send the original page view.
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

// Return a wrapper function which sends a Google Analytics event before executing
// the wrapped function, only if analytics has been accepted by the user.
const withGoogleAnalyticsEvent = (event: EventArgs) => <T extends any[], U>(
  callback: (...args: T) => Promise<U>,
) => async (...args: T): Promise<U> => {
  sendGoogleAnalyticsEvent(event)();

  return await callback(...args);
};

const sendGoogleAnalyticsEvent = (event: EventArgs) => () => {
  if ('true' !== sessionStorage.getItem('accepted-analytics')) {
    return;
  }

  ReactGA.event(event);
};

export const setGoogleAnalyticsHistoryListener = (history: History): void => {
  if ('true' !== sessionStorage.getItem('accepted-analytics')) {
    return;
  }

  // Send a page view on hostory changes.
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
};

export const withLoginEvent = withGoogleAnalyticsEvent({
  category: EventCategory.USER,
  action: UserEventAction.LOGIN,
});

export const withChangePlayerNameEvent = withGoogleAnalyticsEvent({
  category: EventCategory.USER,
  action: UserEventAction.CHANGE_NAME,
});

export const withChangeLocaleEvent = withGoogleAnalyticsEvent({
  category: EventCategory.USER,
  action: UserEventAction.CHANGE_LOCALE,
});

export const withCreateGameEvent = withGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.CREATE,
});

export const withCreateNextGameEvent = withGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.CREATE_NEXT,
});

export const withLoadGameEvent = withGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.LOAD,
});

export const withRefreshGameEvent = withGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.REFRESH,
});

export const withStartGameEvent = withGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.START,
});

export const withJoinGameEvent = withGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.JOIN,
});

export const withPlayCardEvent = withGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.PLAY_CARD,
});

export const withVoteForCardEvent = withGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.VOTE_FOR_CARD,
});

export const sendViewResultsEvent = sendGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.VIEW_RESULTS,
});

export const sendReviewRoundsEvent = sendGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.REVIEW_ROUNDS,
});

export const withChangeSetCardsEvent = withGoogleAnalyticsEvent({
  category: EventCategory.GAME,
  action: GameEventAction.CHANGE_CARDS,
});
