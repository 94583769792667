import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Emotion as EmotionType } from '../../types/Emotion';
import Emotion from '../Emotion';
import SVGIcon from '../SVGIcon';

export interface ResultCardProps {
  emotions: Array<{
    emotion: EmotionType;
    count: number;
  }>;
  isMyself: boolean;
  player: string;
  rank: number;
  score: number;
}

const ResultCard = ({
  rank,
  emotions,
  isMyself,
  player,
  score,
}: ResultCardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={classNames('votedCard', { first: 1 === rank })}>
      <div className="cardHeader">
        <p>
          {isMyself
            ? t('game.result.player.me', { player })
            : t('game.result.player.other_player', { player })}
        </p>
      </div>
      <div className="cardContent cardResults">
        <ul className="votes">
          {emotions.map(({ emotion, count }) => (
            <li key={emotion}>
              <p className="count">{count}</p>
              <p>
                <Emotion emotion={emotion} />
              </p>
            </li>
          ))}
        </ul>
        {score === 0 ? (
          <div className="iconFail">
            <SVGIcon name="skull" />
          </div>
        ) : null}
        <div className="separator">{t('game.result.total', { score })}</div>
      </div>
    </div>
  );
};

export default ResultCard;
