import { GameActionDispatch, GameActionType } from '../types/Action';

const errorOccurred = (dispatch: GameActionDispatch) => (
  message: string,
): void =>
  dispatch({ type: GameActionType.ERROR_OCCURRED, payload: { message } });

const clearError = (dispatch: GameActionDispatch) => (): void =>
  dispatch({ type: GameActionType.ERROR_CLEARED, payload: undefined });

let lastErrorTimeoutRef: number | undefined = undefined;

export const selfDismissingErrorOccurred = (dispatch: GameActionDispatch) => (
  error: string,
): void => {
  // Display the error message.
  errorOccurred(dispatch)(error);

  // Remove any previous timeout.
  window.clearTimeout(lastErrorTimeoutRef);

  // Dismiss the error message after 5 seconds.
  lastErrorTimeoutRef = window.setTimeout(() => {
    lastErrorTimeoutRef = undefined;
    clearError(dispatch)();
  }, 5000);
};
