import React from 'react';
import { Spring } from 'react-spring/renderprops';
import { Locale } from '../../types/Locale';
import UserOptions from '../Options/UserOptions';

interface AnimationProps {
  animationStyle: {
    opacity: number;
    transform: string;
  };
}

interface InfoProps {
  changeLocale: (locale: Locale) => Promise<void>;
}

const Info = ({
  changeLocale,
  animationStyle,
}: InfoProps & AnimationProps): JSX.Element => (
  <div style={animationStyle} className="infoHome">
    <UserOptions changeLocale={changeLocale} />
  </div>
);

const AnimatedInfo = (props: InfoProps): JSX.Element => (
  <Spring
    from={{ opacity: 0, transform: 'translate(0,-3rem)' }}
    to={{ opacity: 1, transform: 'translate(0,0)' }}
  >
    {(springProps) => <Info {...props} animationStyle={springProps} />}
  </Spring>
);

export default AnimatedInfo;
