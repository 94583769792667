import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Emotion as EmotionType } from '../types/Emotion';

interface EmotionDetails {
  id: EmotionType;
  description: string;
  emoji: string;
}

const emotionList: Record<EmotionType, EmotionDetails> = {
  [EmotionType.SCARY]: {
    id: EmotionType.SCARY,
    description: 'emotion.scary',
    emoji: '\u{1F631}',
  },
  [EmotionType.FUNNY]: {
    id: EmotionType.FUNNY,
    description: 'emotion.funny',
    emoji: '\u{1F602}',
  },
  [EmotionType.DISGUSTING]: {
    id: EmotionType.DISGUSTING,
    description: 'emotion.disgusting',
    emoji: '\u{1F4A9}',
  },
  [EmotionType.NOT_SAFE_FOR_WORK]: {
    id: EmotionType.NOT_SAFE_FOR_WORK,
    description: 'emotion.not_safe_for_work',
    emoji: '\u{1F51E}',
  },
  [EmotionType.CUTE]: {
    id: EmotionType.CUTE,
    description: 'emotion.cute',
    emoji: '\u{1F496}',
  },
};

export const emotions = Object.values(EmotionType).map((emotion) => emotion);

export const getDescription = (emotion: EmotionType, t: TFunction): string =>
  t(emotionList[emotion].description);

interface EmotionProps {
  emotion: EmotionType;
}

const Emotion = ({ emotion }: EmotionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <span
      role="img"
      aria-label={t(emotionList[emotion].description)}
      className="emotion"
    >
      {emotionList[emotion].emoji}
    </span>
  );
};

export default Emotion;
