import React from 'react';
import { useTranslation } from 'react-i18next';
import ManualShare from './ManualShare';

interface ShareSectionProps {
  canShare: boolean;
  canCopy: boolean;
  shareUrl: string;
}

const ShareSection = ({
  canShare,
  canCopy,
  shareUrl,
}: ShareSectionProps): JSX.Element => {
  const { t } = useTranslation();

  if (canShare) {
    return <p className="shareText">{t('share.action_text.share')}</p>;
  }

  if (canCopy) {
    return <p className="shareText">{t('share.action_text.copy')}</p>;
  }

  return <ManualShare shareUrl={shareUrl} />;
};

export default ShareSection;
