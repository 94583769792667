import {
  getPlayer as getPlayerApi,
  updatePlayer as updatePlayerApi,
} from '../api/backEnd';
import { mapApiResponseToPlayer } from '../helpers/apiUtils';
import { GameActionDispatch, GameActionType } from '../types/Action';
import Player from '../types/Player';

const loggedIn = (
  dispatch: GameActionDispatch,
  player: Player,
  accessToken: string,
) =>
  dispatch({
    type: GameActionType.PLAYER_LOGGED_IN,
    payload: { player, accessToken },
  });

export const login = (
  dispatch: GameActionDispatch,
) => async (): Promise<void> => {
  const response = await getPlayerApi();
  const player = mapApiResponseToPlayer(response);
  const accessToken = response.token;

  if ('true' === sessionStorage.getItem('accepted-cookies')) {
    sessionStorage.setItem('access-token', response.token);
  }

  loggedIn(dispatch, player, accessToken);
};

const nameChanged = (dispatch: GameActionDispatch, player: Player) =>
  dispatch({
    type: GameActionType.PLAYER_CHANGED_NAME,
    payload: { player },
  });

export const changeName = (dispatch: GameActionDispatch) => async (
  name: string,
): Promise<void> => {
  const response = await updatePlayerApi(name);
  const player = mapApiResponseToPlayer(response);

  nameChanged(dispatch, player);
};
