import classNames from 'classnames';
import React from 'react';
import { CardId, CardInfo } from '../../types/Card';
import { Emotion } from '../../types/Emotion';
import Credits from '../Credits';
import Card from './Card';
import NavBlock from './NavBlock';

interface BoardProps {
  cards: Array<CardInfo>;
  playersCount: number;
  voteForCard: (cardId: CardId, emotion: Emotion) => Promise<void>;
  hasNextRound: boolean;
  hasPreviousRound: boolean;
  isGameFinished: boolean;
  isShowingResults: boolean;
  nextRound: () => void;
  previousRound: () => void;
}

const Board = ({
  cards,
  playersCount,
  voteForCard,
  hasNextRound,
  hasPreviousRound,
  isGameFinished,
  isShowingResults,
  nextRound,
  previousRound,
}: BoardProps): JSX.Element => (
  <div className="boardPlay">
    {isGameFinished && !isShowingResults ? (
      <NavBlock
        hasNextRound={hasNextRound}
        hasPreviousRound={hasPreviousRound}
        isShowingResults={isShowingResults}
        nextRound={nextRound}
        previousRound={previousRound}
      />
    ) : null}
    <ul
      className={classNames('bunchOfCards', {
        boardFinished: isGameFinished && !isShowingResults,
      })}
    >
      {cards.map((card) => (
        <li key={card.id} className="boardCard">
          <Card
            card={card}
            voteForEmotion={(emotion) => voteForCard(card.id, emotion)}
          />
        </li>
      ))}
      {new Array(playersCount - cards.length).fill(undefined).map((_, id) => (
        <li key={id} className="boardCard">
          <Card
            card={undefined}
            voteForEmotion={async () => {
              /* Do nothing. */
            }}
          />
        </li>
      ))}
    </ul>
    <div className="banner">
      <Credits />
    </div>
  </div>
);

export default Board;
