import React from 'react';
import { useParams } from 'react-router-dom';
import {
  hasPlayerJoinedGame,
  isGameOwner,
  isGameWaitingForPlayers,
} from '../../helpers/gameUtils';
import Card, { CardId } from '../../types/Card';
import { Emotion } from '../../types/Emotion';
import Game, { GameId } from '../../types/Game';
import { Locale } from '../../types/Locale';
import Player, { PlayerId } from '../../types/Player';
import { RoundId } from '../../types/Round';
import Play from '../Play/Play';
import GameNotFound from './GameNotFound';
import LoadingGame from './LoadingGame';
import WaitForPlayers from './WaitForPlayers';

interface PageWithGameIdProps {
  changeLocale: (locale: Locale) => Promise<void>;
  changePlayerName: (name: string) => Promise<void>;
  isGameNotFound: boolean;
  joinGame: (gameId: GameId) => Promise<void>;
  game?: Game;
  player?: Player;
  playCard: (gameId: GameId, roundId: RoundId, card: Card) => Promise<void>;
  startGame: (gameId: GameId) => Promise<void>;
  newGame: (gameId: GameId) => Promise<void>;
  loadGame: (gameId: GameId) => Promise<void>;
  refreshGame: (gameId: GameId) => Promise<void>;
  voteForCard: (
    gameId: GameId,
    roundId: RoundId,
    cardId: CardId,
    emotion: Emotion,
  ) => Promise<void>;
  changeSetCards: (gameId: GameId, playerId: PlayerId) => Promise<void>;
}

const PageWithGameId = ({
  game,
  player,
  playCard,
  startGame,
  newGame,
  loadGame,
  refreshGame,
  voteForCard,
  isGameNotFound,
  joinGame,
  changeSetCards,
  changeLocale,
  changePlayerName,
}: PageWithGameIdProps): JSX.Element => {
  const { id: gameId } = useParams<{ id: GameId }>();

  if (isGameNotFound && undefined !== player) {
    return (
      <GameNotFound
        changeLocale={changeLocale}
        changePlayerName={changePlayerName}
        gameId={gameId}
        player={player}
      />
    );
  }

  if (undefined === game || undefined === player) {
    return <LoadingGame loadGame={() => loadGame(gameId)} />;
  }

  if (isGameWaitingForPlayers(game)) {
    return (
      <WaitForPlayers
        changeLocale={changeLocale}
        changePlayerName={changePlayerName}
        refreshGame={() => refreshGame(game.id)}
        hasJoined={hasPlayerJoinedGame(game, player)}
        joinGame={() => joinGame(game.id)}
        startGame={() => startGame(game.id)}
        isOwner={isGameOwner(game, player)}
        player={player}
        players={game.players}
      />
    );
  }

  return (
    <Play
      changeLocale={changeLocale}
      changePlayerName={changePlayerName}
      game={game}
      newGame={() => newGame(game.id)}
      player={player}
      playCard={playCard}
      refreshGame={() => refreshGame(game.id)}
      voteForCard={voteForCard}
      changeSetCards={changeSetCards}
    />
  );
};

export default PageWithGameId;
