import { GameAction, GameActionType } from '../types/Action';
import { GameState } from '../types/State';

export const initialState: GameState = {
  game: undefined,
  hasAnsweredCookieBanner: false,
  isGameLoading: false,
  isGameNotFound: false,
  player: undefined,
};

export const lazyStateInitializer = (state: GameState): GameState => {
  const cookies = sessionStorage.getItem('accepted-cookies');
  const analytics = sessionStorage.getItem('accepted-analytics');

  return {
    ...state,
    hasAnsweredCookieBanner: null !== cookies && null !== analytics,
  };
};

export default (state: GameState, action: GameAction): GameState => {
  switch (action.type) {
    case GameActionType.COOKIES_ACCEPT_ALL:
    case GameActionType.COOKIES_REJECT_ALL:
      return { ...state, hasAnsweredCookieBanner: true };

    case GameActionType.ERROR_CLEARED:
      return { ...state, error: undefined };

    case GameActionType.ERROR_OCCURRED:
      return { ...state, error: action.payload.message, isGameLoading: false };

    case GameActionType.GAME_LOADED:
      return {
        ...state,
        game: action.payload.game,
        isGameLoading: false,
        isGameNotFound: false,
      };

    case GameActionType.GAME_LOADING:
      return {
        ...state,
        game: undefined,
        isGameLoading: true,
        isGameNotFound: false,
      };

    case GameActionType.GAME_NOT_FOUND:
      return {
        ...state,
        game: undefined,
        isGameLoading: false,
        isGameNotFound: true,
      };

    case GameActionType.PLAYER_CHANGED_NAME:
      return { ...state, player: action.payload.player };

    case GameActionType.PLAYER_LOGGED_IN:
      return {
        ...state,
        player: action.payload.player,
        accessToken: action.payload.accessToken,
      };
  }
};
