import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlayerId } from '../../types/Player';

interface ResultHand {
  results: Array<{
    isMyself: boolean;
    player: string;
    playerId: PlayerId;
    rank: number;
  }>;
}

const ResultsHand = ({ results }: ResultHand): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ul className="handPlay">
      {results.map(({ rank, player, playerId, isMyself }) => (
        <li className="playerListItem" key={playerId}>
          <span>
            {isMyself
              ? t('game.result.rank.me', { player, rank })
              : t('game.result.rank.other_player', { player, rank })}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default ResultsHand;
