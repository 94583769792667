import React from 'react';
import { useTranslation } from 'react-i18next';

interface CookieBannerProps {
  accept: () => void;
  reject: () => void;
  hasAnswered: boolean;
}

const CookieBanner = ({
  accept,
  reject,
  hasAnswered,
}: CookieBannerProps): JSX.Element | null => {
  const { t } = useTranslation();

  return hasAnswered ? null : (
    <>
      <div className="cookieBannerOverlay" />
      <div className="cookieBanner">
        <p>
          {t('cookie_banner.disclaimer.own_usage')}
          <br />
          {t('cookie_banner.disclaimer.third_pary_usage')}
          <br />
          <em>{t('cookie_banner.disclaimer.question')}</em>
        </p>
        <div className="buttonsContainer">
          <button onClick={accept}>
            {t('cookie_banner.action.accept_all')}
          </button>
          <button onClick={reject}>
            {t('cookie_banner.action.reject_all')}
          </button>
        </div>
      </div>
    </>
  );
};

export default CookieBanner;
