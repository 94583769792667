import React from 'react';
import { useTranslation } from 'react-i18next';

const HeaderNoAnim = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <h1>{t('title')}</h1>
    </div>
  );
};

export default HeaderNoAnim;
