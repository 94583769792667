import React from 'react';

interface ErrorBannerProps {
  message?: string;
}

const ErrorBanner = ({ message }: ErrorBannerProps): JSX.Element | null =>
  undefined === message ? null : (
    <div className="errorBanner">
      <p>{message}</p>
    </div>
  );

export default ErrorBanner;
