import React, { useEffect } from 'react';
import { Locale } from '../../types/Locale';
import Player from '../../types/Player';
import PlayerInfo from '../Play/PlayerInfo';
import BoardWait from './BoardWait';
import HeaderNoAnim from './HeaderNoAnim';

interface WaitForPlayersProps {
  changeLocale: (locale: Locale) => Promise<void>;
  changePlayerName: (name: string) => Promise<void>;
  refreshGame: () => Promise<void>;
  startGame: () => Promise<void>;
  player: Player;
  hasJoined: boolean;
  isOwner: boolean;
  players: Array<Player>;
  joinGame: () => Promise<void>;
}

const WaitForPlayers = ({
  changeLocale,
  changePlayerName,
  refreshGame,
  startGame,
  player,
  hasJoined,
  isOwner,
  players,
  joinGame,
}: WaitForPlayersProps): JSX.Element => {
  useEffect(() => {
    const refresh = setInterval(() => {
      refreshGame();
    }, 1000);

    return () => clearInterval(refresh);
  });

  return (
    <div className="App">
      <HeaderNoAnim />
      <PlayerInfo
        changeLocale={changeLocale}
        changeName={changePlayerName}
        playerName={player.name}
      />
      <BoardWait
        startGame={startGame}
        hasJoined={hasJoined}
        isOwner={isOwner}
        joinGame={joinGame}
      />
      <ul className="handPlay">
        {players.map(({ name, id }) => (
          <li key={id} className="playerListItem">
            <span>{name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WaitForPlayers;
