import React, { useEffect, useMemo, useState } from 'react';
import {
  canChangeCards,
  getHandCards,
  getLastRoundIndex,
  getPlayedCards,
  getResults,
  getRoundId,
  getRoundSentence,
  hasPlayedInRound,
  hasPlayerPlayedInRound,
  isGameFinished,
} from '../../helpers/gameUtils';
import {
  sendReviewRoundsEvent,
  sendViewResultsEvent,
} from '../../helpers/googleAnalyticsUtils';
import Card, { CardId } from '../../types/Card';
import { Emotion } from '../../types/Emotion';
import Game, { GameId } from '../../types/Game';
import { Locale } from '../../types/Locale';
import Player, { PlayerId } from '../../types/Player';
import { RankedPlayer } from '../../types/Results';
import { RoundId } from '../../types/Round';
import Results from '../Result/Results';
import ResultsHand from '../Result/ResultsHand';
import Board from './Board';
import Hand from './Hand';
import PlayerInfo from './PlayerInfo';
import RoundCard from './RoundCard';

interface PlayProps {
  changeLocale: (locale: Locale) => Promise<void>;
  changePlayerName: (name: string) => Promise<void>;
  game: Game;
  playCard: (gameId: GameId, roundId: RoundId, card: Card) => Promise<void>;
  player: Player;
  voteForCard: (
    gameId: GameId,
    roundId: RoundId,
    cardId: CardId,
    emotion: Emotion,
  ) => Promise<void>;
  newGame: () => Promise<void>;
  refreshGame: () => Promise<void>;
  changeSetCards: (gameId: GameId, playerId: PlayerId) => Promise<void>;
}

const Play = ({
  changeLocale,
  changePlayerName,
  game,
  playCard,
  player,
  voteForCard,
  newGame,
  refreshGame,
  changeSetCards,
}: PlayProps): JSX.Element => {
  const isFinished = isGameFinished(game);

  useEffect(() => {
    if (!isFinished) {
      const refresh = setInterval(() => {
        refreshGame();
      }, 1000);

      return () => clearInterval(refresh);
    }
  });

  const lastRoundIndex = getLastRoundIndex(game);

  const [roundIndex, setRoundIndex] = useState<number>(lastRoundIndex);
  const [showResults, setShowResults] = useState<boolean>(isFinished);

  const hasNextRound = roundIndex < lastRoundIndex;
  const hasPreviousRound = roundIndex > 0;

  const nextRound = () => {
    setRoundIndex(roundIndex + 1);
  };

  const previousRound = () => {
    setRoundIndex(roundIndex - 1);
  };

  const toggleResults = () => {
    if (showResults) {
      sendReviewRoundsEvent();
    } else {
      sendViewResultsEvent();
    }

    setShowResults(!showResults);
  };

  const canChangeSetCards =
    canChangeCards(game, player) &&
    !hasPlayerPlayedInRound(game, roundIndex, player);

  const results = useMemo<Array<RankedPlayer>>(() => getResults(game, player), [
    game,
    player,
  ]);

  return (
    <div className="App">
      <RoundCard
        hasNextRound={hasNextRound}
        isGameFinished={isFinished}
        isShowingResults={showResults}
        newGame={newGame}
        nextRound={nextRound}
        roundCard={getRoundSentence(game, roundIndex)}
        toggleResults={toggleResults}
        changeSetCards={() => changeSetCards(game.id, player.id)}
        canChangeSetCards={canChangeSetCards}
      />
      <PlayerInfo
        changeLocale={changeLocale}
        changeName={changePlayerName}
        playerName={player.name}
      />
      {showResults ? (
        <Results results={results} />
      ) : (
        <Board
          cards={getPlayedCards(game, roundIndex, player)}
          playersCount={game.players.length}
          voteForCard={(cardId, emotion) =>
            voteForCard(game.id, getRoundId(game, roundIndex), cardId, emotion)
          }
          hasNextRound={hasNextRound}
          hasPreviousRound={hasPreviousRound}
          isGameFinished={isFinished}
          isShowingResults={showResults}
          nextRound={nextRound}
          previousRound={previousRound}
        />
      )}
      {showResults ? (
        <ResultsHand results={results} />
      ) : (
        <Hand
          handCards={getHandCards(game, player)}
          playCard={(card) =>
            playCard(game.id, getRoundId(game, roundIndex), card)
          }
          hasPlayed={hasPlayedInRound(game, roundIndex, player)}
        />
      )}
    </div>
  );
};

export default Play;
