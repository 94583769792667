import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spring } from 'react-spring/renderprops';

interface AnimationProps {
  animationStyle: {
    opacity: number;
    transform: string;
  };
}

const Header = ({ animationStyle }: AnimationProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div style={animationStyle} className="header">
      <h1>{t('title')}</h1>
    </div>
  );
};

const AnimatedHeader = (): JSX.Element => (
  <Spring
    from={{ opacity: 0, transform: 'translate(0,-3rem)' }}
    to={{ opacity: 1, transform: 'translate(0,0)' }}
  >
    {(springProps) => <Header animationStyle={springProps} />}
  </Spring>
);

export default AnimatedHeader;
