import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardInfo } from '../../types/Card';
import { Emotion as EmotionType } from '../../types/Emotion';
import Emotion, { emotions, getDescription } from '../Emotion';
import SVGIcon from '../SVGIcon';

interface CardProps {
  card?: CardInfo;
  voteForEmotion: (emotion: EmotionType) => Promise<void>;
}

const Card = ({ card, voteForEmotion }: CardProps): JSX.Element => {
  const { t } = useTranslation();

  // No-one played this card yet
  if (undefined === card) {
    return (
      <div className="notPlayedCard">
        <SVGIcon name="quarantine" />
      </div>
    );
  }

  // Everyone finished voting
  if (card.canShowVotes) {
    const groupedVotes = card.votes.reduce((votes, emotion) => {
      if (!votes.has(emotion)) {
        votes.set(emotion, 0);
      }

      votes.set(emotion, votes.get(emotion) + 1);

      return votes;
    }, new Map());

    return (
      <div className="votedCard">
        <div className="cardHeader">
          <p>{card.isMyCard ? t('game.my_card') : card.player}</p>
        </div>
        <div className="cardContent">
          <p>{card.playedCard}</p>
        </div>
        <ul className="votesForThisCard">
          {Array.from(groupedVotes.entries()).map(([emotion, count]) => (
            <li key={emotion} className="vote">
              {count > 1 ? <span>{count}</span> : null}
              <Emotion emotion={emotion} />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  if (card.isMyCard) {
    return (
      <div className="myCard">
        <div className="cardHeader">
          <p>{t('game.my_card')}</p>
        </div>
        <div className="cardContent">
          <p>{card.playedCard}</p>
        </div>
      </div>
    );
  }

  if (card.canVote && !card.hasVoted) {
    return (
      <div className="playedCard">
        <div className="playedCardContent">
          <div className="cardHeader"></div>
          <div className="cardContent">
            <p>{card.playedCard}</p>
          </div>
        </div>
        <ul className="voteOverlay">
          {emotions.map((emotion) => (
            <li
              key={emotion}
              className="voteBtn"
              onClick={() => voteForEmotion(emotion)}
            >
              <Emotion emotion={emotion} />
              <p>{getDescription(emotion, t)}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  // Current player voted but others still haven't
  if (card.hasVoted && !card.canShowVotes) {
    return (
      <div className="votedCard">
        <div className="cardHeader"></div>
        <div className="cardContent">
          <p>{card.playedCard}</p>
        </div>
        <div className="votesForThisCard">
          {card.myVote ? (
            <span className="vote">
              <Emotion emotion={card.myVote} />
            </span>
          ) : null}
        </div>
      </div>
    );
  }

  // Someone else's played card, cannot yet vote
  return (
    <div className="hiddenPlayedCard">
      <SVGIcon name="virusShield" />
    </div>
  );
};

export default Card;
