import { GameActionDispatch, GameActionType } from '../types/Action';

export const acceptAllCookies = (
  dispatch: GameActionDispatch,
  language: string,
  accessToken: string | null,
) => (): void => {
  sessionStorage.setItem('accepted-cookies', 'true');
  sessionStorage.setItem('accepted-analytics', 'true');

  // Save the locale in the session storage.
  sessionStorage.setItem('locale', language);

  // Save the user token in the session storage.
  if (null !== accessToken) {
    sessionStorage.setItem('access-token', accessToken);
  }

  dispatch({
    type: GameActionType.COOKIES_ACCEPT_ALL,
    payload: undefined,
  });
};

export const rejectAllCookies = (dispatch: GameActionDispatch) => (): void => {
  dispatch({
    type: GameActionType.COOKIES_REJECT_ALL,
    payload: undefined,
  });
};
