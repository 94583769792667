import React from 'react';
import { useTranslation } from 'react-i18next';
import SVGIcon from '../SVGIcon';

interface NewGameProps {
  showOptions: () => void;
}

const NewGame = ({ showOptions }: NewGameProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <div className="btnWrapper">
      <SVGIcon name="virus" />
      <button onClick={showOptions}>{t('game.navigation.new_game')}</button>
    </div>
  );
};

export default NewGame;
