import React from 'react';
import { PlayerId } from '../../types/Player';
import Credits from '../Credits';
import ResultCard, { ResultCardProps } from './ResultCard';

interface Result extends ResultCardProps {
  playerId: PlayerId;
}

interface ResultsProps {
  results: Array<Result>;
}

const Results = ({ results }: ResultsProps): JSX.Element => (
  <div className="resultWrap">
    <ul className="resultBoard">
      {results.map((result) => (
        <li key={result.playerId} className="resultCard">
          <ResultCard
            rank={result.rank}
            isMyself={result.isMyself}
            player={result.player}
            emotions={result.emotions}
            score={result.score}
          />
        </li>
      ))}
    </ul>
    <div className="banner">
      <Credits />
    </div>
  </div>
);

export default Results;
