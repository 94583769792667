import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Spring } from 'react-spring/renderprops';
import Emotion, { emotions, getDescription } from '../Emotion';
import SVGIcon from '../SVGIcon';

interface AnimationProps {
  animationStyle: {
    opacity: number;
    transform: string;
  };
}

const Rules = ({ animationStyle }: AnimationProps): JSX.Element => {
  const { t } = useTranslation();

  const NextButton = () => (
    <button className="roundBtn" aria-label="Next round">
      <SVGIcon name="rightArrow" />
    </button>
  );

  const ChangeCards = () => (
    <button className="roundBtn" aria-label="change cards">
      <SVGIcon name="refresh" />
    </button>
  );

  return (
    <div className="handHome" style={animationStyle}>
      <h1>{t('rules.title')}</h1>
      <p>{t('rules.options')}</p>
      <p>{t('rules.rounds')}</p>
      <ul>
        {emotions.map((emotion) => (
          <li key={emotion}>
            <Emotion emotion={emotion} /> {getDescription(emotion, t)}
          </li>
        ))}
      </ul>
      <p>
        <Trans
          i18nKey="rules.vote"
          components={{
            b: <b />,
          }}
        />
      </p>
      <p>
        <Trans
          i18nKey="rules.next_round"
          components={{
            b: <b />,
            next: <NextButton />,
          }}
        />
      </p>
      <p>
        <Trans
          i18nKey="rules.change_cards"
          components={{
            b: <b />,
            change: <ChangeCards />,
          }}
        />
      </p>
    </div>
  );
};

const AnimatedRules = (): JSX.Element => (
  <Spring
    from={{ opacity: 0, transform: 'translate(3rem,0)' }}
    to={{ opacity: 1, transform: 'translate(0,0)' }}
    delay={500}
  >
    {(springProps) => <Rules animationStyle={springProps} />}
  </Spring>
);

export default AnimatedRules;
