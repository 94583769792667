import React from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../types/Locale';

interface OptionsProps {
  changeLocale: (locale: Locale) => Promise<void>;
}

const UserOptions = ({ changeLocale }: OptionsProps): JSX.Element => {
  const { i18n } = useTranslation();

  const locale = i18n.language;

  return (
    <div className="userOptions">
      <select
        value={locale}
        onChange={(event) => changeLocale(event.target.value as Locale)}
      >
        <option value={Locale.ENGLISH}>{'\u{1F1EC}\u{1F1E7}'}</option>
        <option value={Locale.FRENCH}>{'\u{1F1EB}\u{1F1F7}'}</option>
      </select>
    </div>
  );
};

export default UserOptions;
