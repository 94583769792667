export enum EventCategory {
  GAME = 'game',
  USER = 'user',
}

export enum GameEventAction {
  CHANGE_CARDS = 'change-cards',
  CREATE = 'create',
  CREATE_NEXT = 'create-next',
  JOIN = 'join',
  LOAD = 'load',
  PLAY_CARD = 'play-card',
  REFRESH = 'refresh',
  START = 'start',
  REVIEW_ROUNDS = 'review-rounds',
  VIEW_RESULTS = 'results',
  VOTE_FOR_CARD = 'vote-for-card',
}

export enum UserEventAction {
  CHANGE_LOCALE = 'change-locale',
  CHANGE_NAME = 'change-name',
  LOGIN = 'login',
}
